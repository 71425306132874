<template>
    <div class="page_content">
        <!-- <a-spin size="large" v-show="loading" /> -->
        <div class="page_edit_content">
            <Form
                ref="form"
                :form_data="form_data"
                @handleSubmit="handleSubmit"
                @get_select_value="get_select_value"
            >
                <div slot="customer_name">
                    <a @click="add_customer()">
                        <a-icon
                            v-if="!select_customer.name"
                            type="plus-circle"
                        />
                        <span style="padding-left: 5px">{{
                            select_customer.name
                                ? select_customer.name
                                : "选择客户"
                        }}</span>
                    </a>
                </div>
                <div slot="customer_id">
                    <a-input
                        type="text"
                        name="customer_id"
                        disabled
                        :value="customer_id"
                    />
                </div>
                <div slot="user_info">
                    <a-input
                        type="text"
                        name="user_info"
                        disabled
                        :value="user_name"
                    />
                </div>
                <div slot="material">
                    <div>
                        <div class="material material_title">
                            <p>品项</p>
                            <p>数量</p>
                            <p></p>
                        </div>
                        <div class="material" v-for="item in material_list">
                            <p>{{ item.goods_title }}</p>
                            <p>
                                <a-input
                                    type="number"
                                    name="num"
                                    v-model:value="item.num"
                                />
                            </p>
                            <p class="del">删除</p>
                        </div>
                        <div class="add_material" @click="add_material">
                            <a href="#">添加品项</a>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
        <SelectCustomer
            @submit="select_customer_submit"
            :visible.sync="pid_visible"
        ></SelectCustomer>
        <SelectMaterial
            @submit="select_material_submit"
            :visible.sync="material_visible"
        ></SelectMaterial>
    </div>
</template>

<script>
import Form from "@/components/Form";
import SelectMaterial from "@/components/SelectMaterial";
import SelectCustomer from "@/components/SelectCustomer";
import {
    getModifyCustomerInfo,
    getCustomerDetails,
} from "@/api/customer";

import {
    getPutInSelect,
    getGoodsData,
    getGoodsSelect,
    saveSupervise,
    getSuperviseDetail
} from "@/api/cost";
export default {
    components: {
        Form,
        SelectMaterial,
        SelectCustomer,
    },
    data() {
        return {
            user_name: '',
            customer_id: '',
            num: 0,
            expand: false,
            material_visible: false,
            page: 1,
            list: [],
            material_data: [

            ],
            info: {},
            material_list: [],
            select_customer: [],
            loading: true,
            visible: false,
            pid_visible: false,
            form_data: {
                title: "基本信息",
                show_submit_btn: this.$route.query.type == 'see' ? false : true,
                show_close_btn: true,
                close_reset_btn: true,
                list: [
                    {
                        type: "slot",
                        name: "customer_name",
                        title: "客户名称",
                        mode: "default",
                        options: {
                            rules: [{
                                required: true,
                            }],
                            initialValue: ""
                        },
                        list: []
                    },
                    {
                        type: "slot",
                        name: "customer_id",
                        title: "客户id",
                        options: {
                            initialValue: ""
                        },
                        placeholder: '选了客户后自动关联',
                    },

                    {
                        type: "slot",
                        name: "user_info",
                        title: "监管员",
                        value: '',
                        options: {
                            initialValue: ""
                        }
                    },
                    {
                        type: "select",
                        name: "type",
                        title: "监管类型",
                        mode: "default",
                        options: {
                            rules: [{
                                required: false,
                                message: "请选择监管类型类型"
                            }],
                            initialValue: ""
                        },
                        list: [{
                            key: 'reward',
                            value: '奖励'
                        },
                        {
                            key: 'punishment',
                            value: '惩罚'
                        },
                        {
                            key: 'normal',
                            value: '正常'
                        }
                        ],
                    },
                    {
                        type: "text",
                        name: 'reason',
                        title: "监管原因",
                        close: true,
                        options: {
                            rules: [{
                                required: false,
                                message: "请填监管原因"
                            }],
                            initialValue: ""
                        }
                    },
                    {
                        type: "upload",
                        name: "image",
                        title: "监管图片",
                        options: {
                            initialValue: []
                        }
                    },
                    {
                        type: "select",
                        name: "approval_id",
                        title: "关联投放",
                        mode: "default",
                        placeholder: '请选择关联投放',
                        options: {
                            rules: [{
                                required: true,
                                message: "请选择关联投放"
                            }],
                            initialValue: ""
                        },
                        list: []
                    },
                    {
                        type: "slot",
                        name: "material",
                        title: "物料",
                        options: {
                            initialValue: ""
                        }
                    },


                ]
            },
        };
    },
    async created() {
		if(this.$route.query.id){
			this.get_getSuperviseDetail()
		}
        
    },
    methods: {
        get_getSuperviseDetail() {
            getSuperviseDetail({
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.form_data.list.forEach(item => {
                    if (item.name == "customer_name") {
                        this.select_customer = {
                            name: res.data.list.customer_name,
                        }
                        this.get_putList(res.data.list.customer_id);
                    } else if (item.name == "customer_id") {
                        this.customer_id = res.data.list.customer_id
                    } else if (item.name == "user_info") {
                        this.user_name = res.data.list.username;
                    } else if (item.name == "type") {
                        if (res.data.list.type == '奖励') {
                            item.options.initialValue = 'reward'
                        } else if (res.data.list.type == '惩罚') {
                            item.options.initialValue = 'punishment'
                        } else {
                            item.options.initialValue = 'normal'
                        }

                    } else if (item.name == "reason") {
                        item.options.initialValue = res.data.list.reason;
                    } else if (item.name == "approval_id") {
                        item.options.initialValue = res.data.list.approval_id;
                    }
                    this.material_list = res.data.list.goods_data;
                })
            })
        },
        get_select_value(e) {
            this.get_goods_list(e)
        },
        get_goods_list(id) {
            this.material_list = []
            getGoodsData({
                data: {
                    approval_id: id
                }
            }).then(res => {
                this.material_list = res.data.list
            })
        },
        add_material() {
            this.material_visible = true
        },
        add_customer() {
            this.pid_visible = true;
        },

        select_material_submit({
            data
        }) {
            let obj = {
                goods_id: data.id,
                goods_title: data.goods_title,
                num: 0,
            }
            this.material_list.push(obj)
        },
        select_customer_submit({
            data
        }) {
            this.user_name = this.$store.state.user_info
            this.pid_visible = false;
            this.select_customer = data;
            this.$refs.form.setFieldsValue({
                customer_id: this.select_customer.id
            });
            this.customer_id = this.select_customer.id
            this.get_putList(this.select_customer.id);
        },

        get_putList(id) {

            getPutInSelect({
                data: {
                    customer: id
                }
            }).then(res => {
                let temp_list = [];
                res.data.list.forEach(item => {
                    let obj = {
                        key: item.id,
                        value: item.approval_type_name
                    }
                    temp_list.push(obj)
                })
                this.form_data.list.forEach(item => {
                    if (item.name == "approval_id") {
                        item.list = temp_list;
                    }
                });
            })
        },


        handleSubmit(e) {

            if (this.$route.query.type == 'see') {
                return false;
            }

            this.$method.debounce(() => {
                let value = e.values;
                let obj = {
                    ...value,
                    id: this.$route.query.id,
                    goods_data: JSON.stringify(this.material_list),
                };
                saveSupervise({
                    data: obj
                }).then(res => {
                    this.$router.back();
                })
            })

        }
    }
};
</script>
<style lang="less" scoped>
.map_select {
    position: relative;

    .serach_input {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}

ul {
    padding: 0;
}

li {
    display: block;
    list-style: none;
    display: flex;

    span {
        padding-right: 10px;

        display: inline-block;
    }
}

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.spin-content {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 30px;
}

.material {
    display: flex;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    P {
        flex: 1;
    }

    P:nth-child(1) {
        flex: 2;
    }
}

.material_title {
    background: rgba(229, 229, 229, 1);
}

.add_material {
    color: #40a9ff;

    width: 100rpx;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    border-top: 1px solid #d9d9d9;
}

.del {
    color: #40a9ff;
    cursor: pointer;
}
</style>
