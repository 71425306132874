<template>
    <PopTableList
        :zIndex="2000"
        :columns="pop_columns"
        :visible.sync="user_visible"
        :rowSelection="rowSelection"
        :get_table_list="get_table_list"
        :submit_preprocessing="submit_preprocessing"
        @submit="submit"
        rowKey="id"
    >

    </PopTableList>
</template>

<script>
import PopTableList from './PopTableList'
import { getGoodsSelect } from "@/api/cost.js";
import props_pop from './props/pop'
import table_pop from './props/table'
const columns = [
 
	{
	    title: "品项名称",
	    dataIndex: "goods_title"
	},
]

export default {
    name: 'SelectUser',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop
    },

    data() {
        return {
            user_visible: false,
            get_table_list: getGoodsSelect,
            pop_columns: columns,
            submit_preprocessing: {
            },
            form_data_pop: {
                title: '请选择品项'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "品项关键词",
                        options: {}
                    }
                ]
            },
            select_user: {}
        }
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
					
                    this.select_user = selectedRows[0];
                },
                type: 'radio',
                selections: false,
                hideDefaultSelections: true,
                getCheckboxProps: record => {
                    return {
                        props: {
                            checked: record.id == this.select_user.id ? true : false,
                            name: record.goods_title.toString(),
                        }
                    }
                }
            };
        },
    },
    watch: {
        visible(new_value) {
            this.user_visible = new_value
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
        
    },
    methods: {
        submit() {
            this.$emit('submit', {
                data: this.select_user
            })
        }
    }
}
</script>